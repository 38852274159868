import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule),
    data: {
      breadcrumb: "Dashboard"
    }
  },
  // {
  //   path: 'base',
  //   loadChildren: () => import('../../components/base/base.module').then(m => m.BaseModule),
  //   data: {
  //     breadcrumb: "Base"
  //   }
  // },
  // {
  //   path: 'advance',
  //   loadChildren: () => import('../../components/advance/advance.module').then(m => m.AdvanceModule),
  //   data: {
  //     breadcrumb: "Advance"
  //   }
  // },
  {
    path: 'icons',
    loadChildren: () => import('../../components/icons/icons.module').then(m => m.IconsModule),
    data: {
      breadcrumb: "Icons"
    }
  },
  // {
  //   path: 'buttons',
  //   loadChildren: () => import('../../components/buttons/buttons.module').then(m => m.ButtonsModule),
  //   data: {
  //     breadcrumb: "Buttons"
  //   }
  // },
  // {
  //   path: 'chart',
  //   loadChildren: () => import('../../components/charts/charts.module').then(m => m.ChartModule),
  //   data: {
  //     breadcrumb: "Chart"
  //   }
  // },
  // {
  //   path: 'cards',
  //   loadChildren: () => import('../../components/cards/cards.module').then(m => m.CardsModule),
  //   data: {
  //     breadcrumb: "Cards"
  //   }
  // },
  // {
  //   path: 'timeline',
  //   loadChildren: () => import('../../components/timeline/timeline.module').then(m => m.TimelineModule),
  //   data: {
  //     breadcrumb: "Timeline"
  //   }
  // },
  // {
  //   path: 'form',
  //   loadChildren: () => import('../../components/forms/forms.module').then(m => m.FormModule),
  //   data: {
  //     breadcrumb: "Form"
  //   }
  // },
  // {
  //   path: 'gallery',
  //   loadChildren: () => import('../../components/gallery/gallery.module').then(m => m.GalleryDemoModule),
  //   data: {
  //     breadcrumb: "Gallery"
  //   }
  // },
  // {
  //   path: 'table',
  //   loadChildren: () => import('../../components/tables/tables.module').then(m => m.TablesModule),
  //   data: {
  //     breadcrumb: "Table"
  //   }
  // },
  // {
  //   path: 'editor',
  //   loadChildren: () => import('../../components/editor/editor.module').then(m => m.EditorModule),
  //   data: {
  //     breadcrumb: "Editor"
  //   }
  // },
  {
    path: 'users',
    loadChildren: () => import('../../components/users/users.module').then(m => m.UsersModule),
    data: {
      breadcrumb: "Users"
    }
  },
  // {
  //   path: 'to-do',
  //   loadChildren: () => import('../../components/to-do/to-do.module').then(m => m.ToDoModule),
  //   data: {
  //     breadcrumb: "To-do"
  //   }
  // },
  // {
  //   path: 'email',
  //   loadChildren: () => import('../../components/email/email.module').then(m => m.EmailModule),
  //   data: {
  //     breadcrumb: "Email"
  //   }
  // },
  // {
  //   path: 'blog',
  //   loadChildren: () => import('../../components/blog/blog.module').then(m => m.BlogModule),
  //   data: {
  //     breadcrumb: "Blog"
  //   }
  // },
  // {
  //   path: 'social-app',
  //   loadChildren: () => import('../../components/social-app/social-app.module').then(m => m.SocialAppModule),
  //   data: {
  //     breadcrumb: "Social-app"
  //   }
  // },
  // {
  //   path: 'job-search',
  //   loadChildren: () => import('../../components/job-search/job-search.module').then(m => m.JobSearchModule),
  //   data: {
  //     breadcrumb: "Job-Search"
  //   }
  // },
  // {
  //   path: 'learning',
  //   loadChildren: () => import('../../components/learning/learning.module').then(m => m.LearningModule),
  //   data: {
  //     breadcrumb: "Learning"
  //   }
  // },
  // {
  //   path: 'faq',
  //   loadChildren: () => import('../../components/faq/faq.module').then(m => m.FaqModule),
  //   data: {
  //     breadcrumb: "Faq"
  //   }
  // },
  // {
  //   path: 'support-ticket',
  //   loadChildren: () => import('../../components/support-ticket/support-ticket.module').then(m => m.SupportTicketModule),
  //   data: {
  //     breadcrumb: "Support-Ticket"
  //   }
  // },
  // {
  //   path: 'pricing',
  //   loadChildren: () => import('../../components/pricing/pricing.module').then(m => m.PricingModule),
  //   data: {
  //     breadcrumb: "Pricing"
  //   }
  // },
  // {
  //   path: 'knowledgebase',
  //   loadChildren: () => import('../../components/knowledge-base/knowledge-base.module').then(m => m.KnowledgeBaseModule),
  //   data: {
  //     breadcrumb: "Knowledge-Base"
  //   }
  // },
  // {
  //   path: 'search-result',
  //   loadChildren: () => import('../../components/search-result/search-result.module').then(m => m.SearchResultModule),
  //   data: {
  //     breadcrumb: "Search-Result"
  //   }
  // },
  // {
  //   path: 'sample-page',
  //   loadChildren: () => import('../../components/sample-page/sample-page.module').then(m => m.SamplePageModule),
  //   data: {
  //     breadcrumb: "Sample-Page"
  //   }
  // },
  // {
  //   path: 'map',
  //   loadChildren: () => import('../../components/map/map.module').then(m => m.MapModule),
  //   data: {
  //     breadcrumb: "Map"
  //   }
  // },
  // {
  //   path: 'ui-elements',
  //   loadChildren: () => import('../../components/ui-elements/ui-elements.module').then(m => m.UiElementsModule),
  //   data: {
  //     breadcrumb: "UI-Elements"
  //   }
  // },
  // {
  //   path: 'widgets',
  //   loadChildren: () => import('../../components/widgets/widgets.module').then(m => m.WidgetsModule),
  //   data: {
  //     breadcrumb: "Widgets"
  //   }
  // },
  // {
  //   path: 'ecommerce',
  //   loadChildren: () => import('../../components/e-commerce/e-commerce.module').then(m => m.ECommerceModule),
  //   data: {
  //     breadcrumb: "Ecommerce"
  //   }
  // },
  // {
  //   path: 'chat',
  //   loadChildren: () => import('../../components/chat/chat.module').then(m => m.ChatModule),
  //   data: {
  //     breadcrumb: "Chat"
  //   }
  // },
  // {
  //   path: 'contact',
  //   loadChildren: () => import('../../components/contact/contact.module').then(m => m.ContactModule),
  //   data: {
  //     breadcrumb: "Contact"
  //   }
  // },
  {
    path: 'masters',
    loadChildren: () => import('../../components/masters/masters.module').then(m => m.MastersModule),
    data: {
      breadcrumb: "Masters"
    }
  },
  {
    path: 'academics',
    loadChildren: () => import('../../components/academics/academics.module').then(m => m.AcademicsModule),
    data: {
      breadcrumb: "Academics"
    }
  },
  {
    path: 'preexam',
    loadChildren: () => import('../../components/preexam/preexam.module').then(m => m.PreexamModule),
    data: {
      breadcrumb: "Pre-Exam"
    }
  },
  {
    path: 'qpprocess',
    loadChildren: () => import('../../components/qpprocess/qpprocess.module').then(m => m.QpprocessModule),
    data: {
      breadcrumb: "QP-Process"
    }
  },
  {
    path: 'postexam',
    loadChildren: () => import('../../components/postexam/postexam.module').then(m => m.PostexamModule),
    data: {
      breadcrumb: "Post-Exam"
    }
  },
  {
    path: 'feedback',
    loadChildren: () => import('../../components/feedback/feedback.module').then(m => m.FeedbackModule),
    data: {
      breadcrumb: "Feedback"
    }
  },
  {
    path: 'resultprocessing',
    loadChildren: () => import('../../components/resultprocessing/resultprocessing.module').then(m => m.ResultprocessingModule),
    data: {
      breadcrumb: "Result Processing"
    }
  },
  {
    path: 'certificate',
    loadChildren: () => import('../../components/certificate/certificate.module').then(m => m.CertificateModule),
    data: {
      breadcrumb: "Certificate"
    }
  },
  {
    path: 'convocation',
    loadChildren: () => import('../../components/convocation/convocation.module').then(m => m.ConvocationModule),
    data: {
      breadcrumb: "Convocation"
    }
  },
  {
    path: 'hostelmanagement',
    loadChildren: () => import('../../components/hostelmanagement/hostelmanagement.module').then(m => m.HostelmanagementModule),
    data: {
      breadcrumb: "Hostel Management"
    }
  },
  {
    path: 'feemanagement',
    loadChildren: () => import('../../components/feemanagement/feemanagement.module').then(m => m.FeemanagementModule),
    data: {
      breadcrumb: "Fee Management"
    }
  },
  {
    path: 'helpdesk',
    loadChildren: () => import('../../components/helpdesk/helpdesk.module').then(m => m.HelpdeskModule),
    data: {
      breadcrumb: "Helpdesk"
    }
  },
  {
    path: 'notification',
    loadChildren: () => import('../../components/notification/notification.module').then(m => m.NotificationModule),
    data: {
      breadcrumb: "Notification"
    }
  },
  {
    path: 'utility',
    loadChildren: () => import('../../components/utility/utility.module').then(m => m.UtilityModule),
    data: {
      breadcrumb: "Utility"
    }
  },
  {
    path: 'admin',
    loadChildren: () => import('../../components/admin/admin.module').then(m => m.AdminModule),
    data: {
      breadcrumb: "Admin"
    }
  },
  {
    path: 'faculty',
    loadChildren: () => import('../../components/faculty/faculty.module').then(m => m.FacultyModule),
    data: {
      breadcrumb: "Faculty"
    }
  },
  {
    path: 'student',
    loadChildren: () => import('../../components/student/student.module').then(m => m.StudentModule),
    data: {
      breadcrumb: "Student"
    }
  },
  {
    path: 'reports',
    loadChildren: () => import('../../components/reports/reports.module').then(m => m.ReportsModule),
    data: {
      breadcrumb: "Reports"
    }
  },
  {
    path: 'pgforms',
    loadChildren: () => import('../../components/pgforms/pgforms.module').then(m => m.PgformsModule),
    data: {
      breadcrumb: "PG Forms"
    }
  },
  {
    path: 'reevaluation',
    loadChildren: () => import('../../components/reevaluation/reevaluation.module').then(m => m.ReevaluationModule),
    data: {
      breadcrumb: "Re-Evaluation"
    }
  },
  {
    path: 'secondvaluation',
    loadChildren: () => import('../../components/secondvaluation/secondvaluation.module').then(m => m.SecondvaluationModule),
    data: {
      breadcrumb: "Second-Evaluation"
    }
  },
  {
    path: 'midterm',
    loadChildren: () => import('../../components/midterm/midterm.module').then(m => m.MidtermModule),
    data: {
      breadcrumb: "Midterm"
    }
  }
];
