import { Injectable, HostListener, OnInit, AfterViewInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';

// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService implements OnInit {

	finData = new Array()
	finalsArray = new Array();
	menuItemArray = new Array()
	rolesdetails: any
	public screenWidth: any
	public collapseSidebar: boolean = false
	public fullScreen = false;
	private itemsSubject = new BehaviorSubject<Menu[]>([]);

	constructor() {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}

	}

	get items() {
		return this.itemsSubject.asObservable();
	}


	ngOnInit() {
		// this.userDetails = localStorage.getItem("currentUser");

	}

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?: any) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		{
			path: '/dashboard/university', title: 'Dashboard', icon: 'home', type: 'link'
		},
		// {
		// 	title: 'Dashboard', icon: 'home', type: 'sub', badgeType: 'primary', badgeValue: 'new', active: false, children: [
		// 		{ path: '/dashboard/default', title: 'Default', type: 'link' },
		// 		{ path: '/dashboard/e-commerce', title: 'E-Commerce', type: 'link', },
		// 		{ path: '', title: 'University', type: 'link' },
		// 		{ path: '/dashboard/bitcoin', title: 'Crypto', type: 'link' },
		// 		{ path: '/dashboard/server', title: 'Server', type: 'link' },
		// 		{ path: '/dashboard/project', title: 'Project', type: 'link' }
		// 	]
		// },
		{
			title: 'Admin', icon: 'lock', type: 'sub', badgeType: 'primary', active: false, children: [
				{ path: '/admin/users', title: 'Users', type: 'link' },
				{ path: '/admin/featuremodulemapping', title: 'Module Feature Mapping', type: 'link', },
				{ path: '/admin/roles', title: 'Roles Feature Mapping', type: 'link' },
				{ path: '/admin/uploaduserphoto', title: 'Upload User Photo', type: 'link' },
				// { path: '/admin/dashboardconfig', title: 'Dashboard Config', type: 'link' },
				{ path: '/admin/paymenttracker', title: 'Payment Tracker', type: 'link' },
				{ path: '/admin/manualquery', title: 'Manual Query', type: 'link' },
				{ path: '/admin/updategradepoint', title: 'Update Gradepoint', type: 'link' },
				{ path: '/admin/getpassword', title: 'Get Password', type: 'link' },
				{ path: '/admin/getevaluatorotp', title: 'Get Evaluator OTP', type: 'link' },
				{ path: '/admin/appversioning', title: 'App Versioning', type: 'link' },
				{ path: '/admin/facultydetails', title: 'Faculty Details', type: 'link' },
				{ path: '/admin/directpayment', title: 'Direct Payment', type: 'link' },
				{ path: '/admin/preloginsetup', title: 'Prelogin Setup', type: 'link' },
				
			]
		},
		{
			title: 'Masters', icon: 'list', type: 'sub', badgeType: 'primary', active: false, children: [
				{ path: '/masters/college', title: 'College', type: 'link' },
				{ path: '/masters/degree', title: 'Degree', type: 'link', },
				{ path: '/masters/category', title: 'Category', type: 'link' },
				{ path: '/masters/academicyear', title: 'Academic Year', type: 'link' },
				{ path: '/masters/program', title: 'Program', type: 'link' },
				{ path: '/masters/discipline', title: 'Discipline', type: 'link' },
				{ path: '/masters/programyear', title: 'Program Year', type: 'link' },
				{ path: '/masters/semester', title: 'Semester', type: 'link' },
				{ path: '/masters/course', title: 'Courses', type: 'link' },
				{ path: '/masters/coursegroup', title: 'Course Group', type: 'link' },
				{ path: '/masters/department', title: 'Department', type: 'link' },
				{ path: '/masters/designation', title: 'Designation', type: 'link' },
				{ path: '/masters/credithours', title: 'Credit Hours', type: 'link' },
				{ path: '/masters/section', title: 'Section', type: 'link' },
				
			]
		},
		{
			title: 'Academics', icon: 'file', type: 'sub', badgeType: 'primary', active: false, children: [
				{ path: '/academics/studentidsetting', title: 'Student ID Setting', type: 'link' },
				{ path: '/academics/studentprofile', title: 'Student Profile', type: 'link', },
				{ path: '/academics/staffprofile', title: 'Staff Profile', type: 'link', },
				{ path: '/academics/updatestudentphoto', title: 'Update Student Photo', type: 'link' },
				{ path: '/academics/updatestaffphoto', title: 'Update Staff Photo', type: 'link' },
				{ path: '/academics/updatestudentsection', title: 'Update Student Section', type: 'link' },
				{ path: '/academics/facultycoursemapping', title: 'Faculty Course Mapping', type: 'link' },
				{ path: '/academics/verifyiamarks', title: 'Verify IA Marks', type: 'link' },
				{ path: '/academics/consellor', title: 'Consellor', type: 'link' },
				{ path: '/academics/bulkregistration', title: 'Bulk Registration', type: 'link' },
				{ path: '/academics/electivecoursemapping', title: 'Elective Course Mapping', type: 'link' },
				{ path: '/academics/deanapproval', title: 'Dean Approval', type: 'link' },
				{ path: '/academics/depromotion', title: 'Depromotion', type: 'link' },
				{ path: '/academics/libraryapproval', title: 'Librarian Approval', type: 'link' },
				{ path: '/academics/hostelapproval', title: 'Hostel Approval', type: 'link' },
				// { path: '/academics/hodinternalmarks', title: 'HOD Internal Marks', type: 'link' },
			]
		},
		{
			title: 'Midterm', icon: 'box', type: 'sub', badgeType: 'primary', active: false, children: [
				{ path: '/midterm/componentmasster', title: 'Component Master', type: 'link' },
				{ path: '/midterm/componentcoursetype', title: 'Component Course Type', type: 'link' },
				{ path: '/midterm/internalmarksconfiguration', title: 'Internal Marks Config', type: 'link' },
				{ path: '/midterm/manualmarksupdates', title: 'Manual Marks Update', type: 'link' },
				{ path: '/midterm/internalmarksreports', title: 'Component Marks Report', type: 'link' },
				{ path: '/midterm/componentmarksreport', title: 'Internal Marks Report', type: 'link' }
			]
		},
		{
			title: 'Pre-Exam', icon: 'users', type: 'sub', badgeType: 'primary', active: false, children: [
				{ path: '/preexam/examination', title: 'Examination', type: 'link' },
				{ path: '/preexam/semesterregistration', title: 'Semester Registration', type: 'link', },
				{ path: '/preexam/seatconfiguration', title: 'Seat Configuration', type: 'link', },
				{ path: '/preexam/examtimetable', title: 'Exam Timetable', type: 'link' },
				{ path: '/preexam/modifytimetable', title: 'Modify Timetable', type: 'link' },
				{ path: '/preexam/downloadtimetable', title: 'Download Timetable', type: 'link' },
				{ path: '/preexam/examattendance', title: 'Exam Attendance', type: 'link' },
				{ path: '/preexam/generateattendance', title: 'Generate Attendance', type: 'link' },
				{ path: '/preexam/allocateinvigilator', title: 'Allocate Invigilator', type: 'link' },
				{ path: '/preexam/generatebarcode', title: 'Generate Barcode', type: 'link' },
				{ path: '/preexam/changexamhall', title: 'Change Exam Hall', type: 'link' },

			]
		},
		{
			title: 'QP Process', icon: 'clipboard', type: 'sub', badgeType: 'primary', active: false, children: [
				{ path: '/qpprocess/qptype', title: 'QP Type', type: 'link' },
				{ path: '/qpprocess/qptopic', title: 'QP Topic', type: 'link', },
				{ path: '/qpprocess/qppattern', title: 'QP Pattern', type: 'link', },
				{ path: '/qpprocess/qpupload', title: 'QP Upload', type: 'link' },
				{ path: '/qpprocess/qpjumbling', title: 'QP Jumbling', type: 'link' },
				{ path: '/qpprocess/qpindexing', title: 'QP Indexing', type: 'link' },
				{ path: '/qpprocess/qpdownload', title: 'QP Download', type: 'link' },

			]
		},
		{
			title: 'Post Exam', icon: 'book', type: 'sub', badgeType: 'primary', active: false, children: [
				{ path: '/postexam/omrupload', title: 'OMR Upload', type: 'link' },
				{ path: '/postexam/externalmarksupload', title: 'External Marks Upload', type: 'link', },
				{ path: '/postexam/verifymarks', title: 'Verify Marks', type: 'link', },
				{ path: '/postexam/evaluatorselection', title: 'Evaluator Selection', type: 'link' },
				{ path: '/postexam/uploadqpandkey', title: 'Upload QP And Keys', type: 'link' },
				{ path: '/postexam/ansbookletupload', title: 'Answer Booklet Upload', type: 'link' },
				{ path: '/postexam/bookletallocation', title: 'Booklet Allocation', type: 'link' },
				{ path: '/postexam/reallocatebooklet', title: 'Re-Allocate Booklet', type: 'link' },
				{ path: '/postexam/sendphotocopy', title: 'Send Photocopy', type: 'link' },
				{ path: '/postexam/remuneration', title: 'Remuneration', type: 'link' },
				{ path: '/postexam/updatestudentabsent', title: 'Update Student Absent', type: 'link' },
				{ path: '/postexam/bookletevaluation', title: 'Evaluation Status', type: 'link' },
			]
		},
		{
			title: 'Re-Evaluation', icon: 'hard-drive', type: 'sub', badgeType: 'primary', active: false, children: [
				{ path: '/reevaluation/generatereevaluationlist', title: 'Re-Evaluation List', type: 'link' },
				{ path: '/reevaluation/bookletallocationrev', title: 'Booklet Allocation', type: 'link' },
				{ path: '/reevaluation/bookletreallocationreva', title: 'Booklet Re-Allocation', type: 'link' },
				{ path: '/reevaluation/reevaluationstatusbooklet', title: 'Re-Evaluation Status', type: 'link' },
			]
		},
		{
			title: 'Second-Evaluation', icon: 'server', type: 'sub', badgeType: 'primary', active: false, children: [
				{ path: '/secondvaluation/generatesecondevaluation', title: 'Second-Evaluation List', type: 'link' },
				{ path: '/secondvaluation/bookletallocationsecond', title: 'Booklet Allocation', type: 'link' },
				{ path: '/secondvaluation/bookletreallocationsecond', title: 'Booklet Re-Allocation', type: 'link' },
				{ path: '/secondvaluation/secondevaluationstatus', title: 'Second-Evaluation Status', type: 'link' },
			]
		},
		{
			title: 'Feedback', icon: 'thumbs-up', type: 'sub', badgeType: 'primary', active: false, children: [
				{ path: '/feedback/feedbackscale', title: 'Feedback Scale', type: 'link' },
				{ path: '/feedback/aboutfeedback', title: 'About Feedback', type: 'link', },
			]
		},
		{
			title: 'Result Processing', icon: 'trending-up', type: 'sub', badgeType: 'primary', active: false, children: [
				{ path: '/resultprocessing/generategradesheet', title: 'Generate Gradesheet', type: 'link' },
				{ path: '/resultprocessing/downloadgradesheet', title: 'Download Gradesheet', type: 'link', },
				{ path: '/resultprocessing/generatecgpa', title: 'Generate CGPA', type: 'link' },
				{ path: '/resultprocessing/downloadcgpa', title: 'Download CGPA', type: 'link', },
			]
		},
		{
			title: 'Certificate', icon: 'file-text', type: 'sub', badgeType: 'primary', active: false, children: [
				{ path: '/certificate/ogpacertificate', title: 'OGPA Certificate', type: 'link' },
				{ path: '/certificate/registrationcard', title: 'Registration Card', type: 'link', },
				{ path: '/certificate/pdccertificate', title: 'PDC Certificate', type: 'link' },
				{ path: '/certificate/migrationcertificate', title: 'Migration Certificate', type: 'link', },
				{ path: '/certificate/degreecertificate', title: 'Degree Certificate', type: 'link', },
				{ path: '/certificate/charactercertificate', title: 'Character Certificate', type: 'link' },
				{ path: '/certificate/degreecompletion', title: 'Degree Completion', type: 'link', },
				{ path: '/certificate/bonafidecertificate', title: 'Bonafide Certificate', type: 'link', },
				{ path: '/certificate/studycertificate', title: 'Study Certificate', type: 'link', },
				{ path: '/certificate/mastersheetug', title: 'Master Sheet UG', type: 'link', },
			]
		},
		{
			title: 'Convocation', icon: 'award', type: 'sub', badgeType: 'primary', active: false, children: [
				{ path: '/convocation/donormaster', title: 'Donor Master', type: 'link' },
				{ path: '/convocation/uploadutility', title: 'Upload Utility', type: 'link', },
				{ path: '/convocation/donorcertificate', title: 'Donor Certificates', type: 'link' },
				{ path: '/convocation/convocationcertificate', title: 'Convocation Certificate', type: 'link', },
				{ path: '/convocation/goldmedalcertificate', title: 'Gold Medal Certificate', type: 'link', },
				{ path: '/convocation/convocationreport', title: 'Convocation Report', type: 'link' },
			]
		},
		{
			title: 'Hostel Management', icon: 'home', type: 'sub', badgeType: 'primary', active: false, children: [
				{ path: '/hostelmanagement/hostelmaster', title: 'Hostel Master', type: 'link' },
				{ path: '/hostelmanagement/hostelfeetype', title: 'Hostel Fee Type', type: 'link', },
				{ path: '/hostelmanagement/hostelfeeconfig', title: 'Hostel Fee Config', type: 'link' },
				{ path: '/hostelmanagement/hostelallocation', title: 'Hostel Allocation', type: 'link', },
				{ path: '/hostelmanagement/hosteldeallocation', title: 'Hostel De-Allocation', type: 'link', },
			]
		},
		{
			title: 'Fee Management', icon: 'credit-card', type: 'sub', badgeType: 'primary', active: false, children: [
				{ path: '/feemanagement/feetype', title: 'Fee Type', type: 'link' },
				{ path: '/feemanagement/createfeename', title: 'Create Fee Name', type: 'link', },
				{ path: '/feemanagement/feemaster', title: 'Fee Master', type: 'link' },
				{ path: '/feemanagement/penaltyconfig', title: 'Penalty Configuration', type: 'link', },
				{ path: '/feemanagement/offlinepayment', title: 'Offline Payment', type: 'link', },
				{ path: '/feemanagement/nriofflinepayment', title: 'NRI Offline Payment', type: 'link', },
				{ path: '/feemanagement/reconciliation', title: 'Reconciliation', type: 'link', },
				{ path: '/feemanagement/reconciliationreport', title: 'Reconciliation Report', type: 'link', },
				{ path: '/feemanagement/refundprocess', title: 'Refund Process', type: 'link', },
				{ path: '/feemanagement/miscellaneousfee', title: 'Miscellaneous Fee', type: 'link', },
				{ path: '/feemanagement/verifyfeess', title: 'Verify Fees', type: 'link', },
			]
		},
		{
			title: 'Helpdesk', icon: 'help-circle', type: 'sub', badgeType: 'primary', active: false, children: [
				{ path: '/helpdesk/helpdeskconsole', title: 'Console', type: 'link' },
			]
		},
		{
			title: 'Notification', icon: 'bell', type: 'sub', badgeType: 'primary', active: false, children: [
				{ path: '/notification/alertcategory', title: 'Alert Category', type: 'link' },
				{ path: '/notification/notificationtemplate', title: 'Notification Template', type: 'link', },
				{ path: '/notification/createnotification', title: 'Create Notification', type: 'link' },

			]
		},
		{
			title: 'Utility', icon: 'message-circle', type: 'sub', badgeType: 'primary', active: false, children: [
				{ path: '/utility/studentsms', title: 'Student SMS', type: 'link' },
				{ path: '/utility/staffsms', title: 'Staff SMS', type: 'link', },
				{ path: '/utility/courseregistration', title: 'Course Registration', type: 'link' },
				{ path: '/utility/dropcourse', title: 'Drop Course', type: 'link' },
				{ path: '/utility/updatecollge', title: 'Update College', type: 'link' },
				{ path: '/utility/barcodedetails', title: 'Barcode Details', type: 'link' },
			]
		},
		{
			title: 'Reports', icon: 'download', type: 'sub', badgeType: 'primary', active: false, children: [
				{ path: '/reports/studentreport', title: 'Student', type: 'link' },
				{ path: '/reports/systemreport', title: 'System', type: 'link', },
				{ path: '/reports/examinationreport', title: 'Examination', type: 'link' },
				{ path: '/reports/resultreport', title: 'Result', type: 'link' },
				{ path: '/reports/accountreport', title: 'Account', type: 'link' },
				{ path: '/reports/historyreports', title: 'History Report', type: 'link' },
			]
		},
		// {
		// 	title: 'Widgets', icon: 'airplay', type: 'sub', active: false, children: [
		// 		{ path: '/widgets/general', title: 'General', type: 'link' },
		// 		{ path: '/widgets/chart', title: 'Chart', type: 'link' }
		// 	]
		// },
		// {
		// 	title: 'UI-Elements', icon: 'slack', type: 'sub', active: false, children: [
		// 		{ path: '/ui-elements/avatars', title: 'Avatars', type: 'link' },
		// 		{ path: '/ui-elements/breadcrumb', title: 'Breadcrumb', type: 'link' },
		// 		{ path: '/ui-elements/grid', title: 'Grid', type: 'link' },
		// 		{ path: '/ui-elements/helper-classes', title: 'Helper-Classes', type: 'link' },
		// 		{ path: '/ui-elements/list', title: 'List', type: 'link' },
		// 		{ path: '/ui-elements/ribbons', title: 'Ribbons', type: 'link' },
		// 		{ path: '/ui-elements/shadow', title: 'Shadow', type: 'link' },
		// 		{ path: '/ui-elements/spinner', title: 'Spinner', type: 'link' },
		// 		{ path: '/ui-elements/steps', title: 'Steps', type: 'link' },
		// 		{ path: '/ui-elements/tag-n-pills', title: 'Tag and Pills', type: 'link' },
		// 		{ path: '/ui-elements/typography', title: 'Typography', type: 'link' }
		// 	]
		// },

		// {
		// 	title: 'Base', icon: 'box', type: 'sub', active: false, children: [
		// 		{ path: '/base/accordion', title: 'Accordion', type: 'link' },
		// 		{ path: '/base/alert', title: 'Alert', type: 'link' },
		// 		{ path: '/base/buttons', title: 'Buttons', type: 'link' },
		// 		{ path: '/base/carousel', title: 'Carousel', type: 'link' },
		// 		{ path: '/base/collapse', title: 'Collapse', type: 'link' },
		// 		{ path: '/base/datepicker', title: 'Datepicker', type: 'link' },
		// 		{ path: '/base/dropdown', title: 'Dropdown', type: 'link' },
		// 		{ path: '/base/modal', title: 'Modal', type: 'link' },
		// 		{ path: '/base/pagination', title: 'Pagination', type: 'link' },
		// 		{ path: '/base/popover', title: 'Popover', type: 'link' },
		// 		{ path: '/base/progressbar', title: 'Progressbar', type: 'link' },
		// 		{ path: '/base/rating', title: 'Rating', type: 'link' },
		// 		{ path: '/base/tabset', title: 'Tabset', type: 'link' },
		// 		{ path: '/base/timepicker', title: 'Timepicker', type: 'link' },
		// 		{ path: '/base/tooltip', title: 'Tooltip', type: 'link' },
		// 		{ path: '/base/typeahead', title: 'Typeahead', type: 'link' }
		// 	]
		// },
		// {
		// 	title: 'Advance', icon: 'folder-plus', type: 'sub', active: false, children: [
		// 		{ path: '/advance/dropzone', title: 'Dropzone', type: 'link' },
		// 		{ path: '/advance/crop', title: 'Image Cropper', type: 'link' },
		// 		{ path: '/advance/toastr', title: 'Ngx Toastr', type: 'link' },
		// 		{ path: '/advance/owl-carousel', title: 'Owl-Carousel', type: 'link' },
		// 		{ path: '/advance/range-slider', title: 'Range Slider', type: 'link' },
		// 		{ path: '/advance/sticky', title: 'Sticky', type: 'link' },
		// 		{ path: '/advance/sweetalert', title: 'SweetAlert', type: 'link' },
		// 	]
		// },
		// {
		// 	title: 'Icons', icon: 'command', type: 'sub', active: false, children: [
		// 		{ path: '/icons/flag', title: 'Flag icon', type: 'link' },
		// 		{ path: '/icons/fontawesome', title: 'Fontawesome Icon', type: 'link' },
		// 		{ path: '/icons/ico', title: 'Ico Icon', type: 'link' },
		// 		{ path: '/icons/themify', title: 'Themify Icon', type: 'link' },
		// 		{ path: '/icons/feather', title: 'Feather Icon', type: 'link' },
		// 		{ path: '/icons/whether', title: 'Whether Icon', type: 'link' }
		// 	]
		// },
		// {
		// 	title: 'Buttons', icon: 'cloud', type: 'sub', active: false, children: [
		// 		{ path: '/buttons/default', title: 'Default Style', type: 'link' },
		// 		{ path: '/buttons/flat', title: 'Flat Style', type: 'link' },
		// 		{ path: '/buttons/edge', title: 'Edge Style', type: 'link' },
		// 		{ path: '/buttons/raised', title: 'Raised Style', type: 'link' },
		// 		{ path: '/buttons/group', title: 'Button Group', type: 'link' }
		// 	]
		// },
		// {
		// 	title: 'Gallery', icon: 'book', type: 'sub', children: [
		// 		{ path: '/gallery/gallery-grid', title: 'Gallery Grid', type: 'link' },
		// 		{ path: '/gallery/gallery-desc', title: 'Gallery Grid With Desc', type: 'link' },
		// 		{ path: '/gallery/mesonry', title: 'Masonry Gallery', type: 'link' },
		// 		{ path: '/gallery/hover-effect', title: 'Hover Effect', type: 'link' }
		// 	]
		// },
		// {
		// 	title: 'Forms', icon: 'file-text', type: 'sub', active: false, children: [
		// 		{
		// 			title: 'Form Controls', type: 'sub', children: [
		// 				{ path: '/form/validation', title: 'Form Validation', type: 'link' },
		// 				{ path: '/form/inputs', title: 'Base Inputs', type: 'link' },
		// 				{ path: '/form/checkbox-radio', title: 'Checkbox & Radio', type: 'link' },
		// 				{ path: '/form/input-groups', title: 'Input Groups', type: 'link' },
		// 				{ path: '/form/mega-options', title: 'Mega Options', type: 'link' }
		// 			]
		// 		},
		// 		{ path: '/form/form-default', title: 'Form Default', type: 'link' },
		// 		{ path: '/form/wizard', title: 'Ngx Wizard', type: 'link' }
		// 	]
		// },

		// {
		// 	title: 'Tables', icon: 'server', type: 'sub', active: false, children: [
		// 		{
		// 			title: 'Bootstrap Tables', type: 'sub', active: false, children: [
		// 				{ path: '/table/basic', title: 'Basic Table', type: 'link' },
		// 				{ path: '/table/sizing', title: 'Sizing Table', type: 'link' },
		// 				{ path: '/table/border', title: 'Border Table', type: 'link' },
		// 				{ path: '/table/styling', title: 'Styling Table', type: 'link' }
		// 			]
		// 		},
		// 		{
		// 			title: 'Ngx-Datatables', type: 'sub', active: false, children: [
		// 				{ path: '/table/ngx-datatables/basic', title: 'Basic Table', type: 'link' },
		// 			]
		// 		}]
		// },
		// {
		// 	title: 'Cards', icon: 'book', type: 'sub', active: false, children: [
		// 		{ path: '/cards/basic', title: 'Basic Card', type: 'link' },
		// 		{ path: '/cards/creative', title: 'Creative Card', type: 'link' },
		// 		{ path: '/cards/tabbed', title: 'Tabbed Card', type: 'link' },
		// 	]
		// },
		// {
		// 	title: 'Timeline', icon: 'sliders', type: 'sub', active: false, children: [
		// 		{ path: '/timeline/timeline1', title: 'Timeline', type: 'link' }
		// 	]
		// },
		// {
		// 	title: 'Charts', icon: 'bar-chart', type: 'sub', active: false, children: [
		// 		{ path: '/chart/google', title: 'Google Chart', type: 'link' },
		// 		{ path: '/chart/chartjs', title: 'Chartjs', type: 'link' },
		// 		{ path: '/chart/chartist', title: 'Chartist', type: 'link' },
		// 		{ path: '/chart/ngx-chart', title: 'Ngx-Chart', type: 'link' }
		// 	]
		// },
		// {
		// 	title: 'Map', icon: 'map', type: 'sub', active: false, children: [
		// 		{ path: '/map/google', title: 'Google Map', type: 'link' },
		// 		{ path: '/map/leaflet', title: 'Leaflet Map', type: 'link' }
		// 	]
		// },
		// {
		// 	path: '/editor', title: 'Editor', icon: 'git-pull-request', type: 'link'
		// },
		// {
		// 	title: 'Users', icon: 'users', type: 'sub', active: false, children: [
		// 		{ path: '/users/profile', title: 'Users Profile', type: 'link' },
		// 		{ path: '/users/edit', title: 'Users Edit', type: 'link' },
		// 		{ path: '/users/cards', title: 'Users Cards', type: 'link' }
		// 	]
		// },
		// // {
		// // 	path: '/calender', title: 'Calender', icon: 'calendar', type: 'link', bookmark: true
		// // },
		// {
		// 	title: 'Blog', icon: 'edit', type: 'sub', active: false, children: [
		// 		{ path: '/blog/details', title: 'Blog Details', type: 'link' },
		// 		{ path: '/blog/single', title: 'Blog Single', type: 'link' }
		// 	]
		// },
		// // {
		// // 	path: '/email', title: 'Email', icon: 'mail', type: 'link', bookmark: true
		// // },
		// {
		// 	path: '/contact/contacts', title: 'contact', icon: 'user-plus', type: 'link'
		// },
		// // {
		// // 	path: '/chat', title: 'Chat', icon: 'message-square', type: 'link', bookmark: true
		// // },
		// {
		// 	path: '/social-app', title: 'Social App', icon: 'chrome', type: 'link'
		// },
		// {
		// 	title: 'Job-Search', icon: 'user-check', type: 'sub', active: false, children: [
		// 		{ path: '/job-search/cardview', title: 'Card View', type: 'link' },
		// 		{ path: '/job-search/listview', title: 'List View', type: 'link' },
		// 		{ path: '/job-search/job-desc/1', title: 'Job Detail', type: 'link' },
		// 		{ path: '/job-search/job-apply/1', title: 'Apply', type: 'link' }
		// 	]
		// },
		// {
		// 	title: 'Learning', icon: 'layers', type: 'sub', active: false, children: [
		// 		{ path: '/learning/learninglist', title: 'Learning List', type: 'link' },
		// 		{ path: '/learning/learning-detail/1', title: 'Detail Course', type: 'link' },
		// 	]
		// },
		// {
		// 	path: '/faq', title: 'FAQ', icon: 'help-circle', type: 'link'
		// },
		// {
		// 	path: '/knowledgebase', title: 'Knowledgebase', icon: 'database', type: 'link'
		// },
		// {
		// 	path: '/support-ticket', title: 'Support Ticket', icon: 'headphones', type: 'link'
		// },
		// {
		// 	path: '/to-do', title: 'To-Do', icon: 'mic', type: 'link'
		// },
		// {
		// 	title: 'Ecommerce', icon: 'shopping-bag', type: 'sub', active: false, children: [
		// 		{ path: '/ecommerce/products', title: 'Product', type: 'link' },
		// 		{ path: '/ecommerce/product-details/1', title: 'Product page', type: 'link' },
		// 		{ path: '/ecommerce/product/list', title: 'Product list', type: 'link' },
		// 		{ path: '/ecommerce/payment/detail', title: 'Payment Details', type: 'link' },
		// 		{ path: '/ecommerce/order', title: 'Order History', type: 'link' }
		// 	]
		// },
		// {
		// 	path: '/pricing', title: 'Pricing', icon: 'dollar-sign', type: 'link'
		// },
		// {
		// 	path: '/sample-page', title: 'Sample page', icon: 'file', type: 'link'
		// },
		// {
		// 	path: '/search-result', title: 'Search Page', icon: 'search', type: 'link'
		// },
		// {
		// 	title: 'Error Page', icon: 'alert-octagon', type: 'sub', active: false, children: [
		// 		{ path: 'error/400', title: 'Error 400', type: 'extTabLink' },
		// 		{ path: 'error/401', title: 'Error 401', type: 'extTabLink' },
		// 		{ path: 'error/403', title: 'Error 403', type: 'extTabLink' },
		// 		{ path: 'error/404', title: 'Error 404', type: 'extTabLink' },
		// 		{ path: 'error/500', title: 'Error 500', type: 'extTabLink' },
		// 		{ path: 'error/503', title: 'Error 503', type: 'extTabLink' }
		// 	]
		// },
		// {
		// 	title: 'Authentication', icon: 'unlock', type: 'sub', active: false, children: [
		// 		{ path: 'authentication/login', title: 'Login Simple', type: 'extTabLink' },
		// 		{ path: 'authentication/login/image', title: 'Login with Bg Image', type: 'extTabLink' },
		// 		{ path: 'authentication/login/video', title: 'Login with Bg video', type: 'extTabLink' },
		// 		{ path: 'authentication/register', title: 'Register Simple', type: 'extTabLink' },
		// 		{ path: 'authentication/register/image', title: 'Register with Bg Image', type: 'extTabLink' },
		// 		{ path: 'authentication/register/video', title: 'Register with Bg video', type: 'extTabLink' },
		// 		{ path: 'authentication/unlockuser', title: 'Unlock User', type: 'extTabLink' },
		// 		{ path: 'authentication/forgetpassword', title: 'Forget Password', type: 'extTabLink' },
		// 		{ path: 'authentication/resetpassword', title: 'Reset Password', type: 'extTabLink' }
		// 	]
		// },
		// {
		// 	title: 'Coming Soon', icon: 'briefcase', type: 'sub', active: false, children: [
		// 		{ path: 'comingsoon/page', title: 'Coming Simple', type: 'extTabLink' },
		// 		{ path: 'comingsoon/page/image', title: 'Coming with Bg Image', type: 'extTabLink' },
		// 		{ path: 'comingsoon/page/video', title: 'Coming with Bg video', type: 'extTabLink' }
		// 	]
		// },
		// {
		// 	path: '/maintenance', title: 'Maintenance', icon: 'settings', type: 'link'
		// },
	]
	MENUITEMSPG: Menu[] = [
		{
			path: '/dashboard/university', title: 'Dashboard', icon: 'home', type: 'link'
		},
		{
			title: 'Admin', icon: 'lock', type: 'sub', badgeType: 'primary', active: false, children: [
				// { path: '/admin/dashboardconfig', title: 'Dashboard Config', type: 'link' },
				{ path: '/admin/paymenttracker', title: 'Payment Tracker', type: 'link' },
				{ path: '/admin/updategradepoint', title: 'Update Gradepoint', type: 'link' },
				{ path: '/admin/getpassword', title: 'Get Password', type: 'link' },
				{ path: '/admin/getevaluatorotp', title: 'Get Evaluator OTP', type: 'link' },
				{ path: '/admin/facultydetails', title: 'Faculty Details', type: 'link' }

			]
		},
		{
			title: 'Masters', icon: 'list', type: 'sub', badgeType: 'primary', active: false, children: [
				{ path: '/masters/college', title: 'College', type: 'link' },
				{ path: '/masters/category', title: 'Category', type: 'link' },
				{ path: '/masters/academicyear', title: 'Academic Year', type: 'link' },
				{ path: '/masters/program', title: 'Program', type: 'link' },
				{ path: '/masters/discipline', title: 'Discipline', type: 'link' },
				{ path: '/masters/course', title: 'Courses', type: 'link' },
				{ path: '/masters/coursegroup', title: 'Course Group', type: 'link' },
				{ path: '/masters/department', title: 'Department', type: 'link' },
				{ path: '/masters/designation', title: 'Designation', type: 'link' },
				{ path: '/masters/credithours', title: 'Credit Hours', type: 'link' },
			]
		},
		{
			title: 'Academics', icon: 'file', type: 'sub', badgeType: 'primary', active: false, children: [
				{ path: '/academics/studentidsetting', title: 'Student ID Setting', type: 'link' },
				{ path: '/academics/studentprofile', title: 'Student Profile', type: 'link', },
				{ path: '/academics/staffprofile', title: 'Staff Profile', type: 'link', },
				{ path: '/academics/updatestudentphoto', title: 'Update Student Photo', type: 'link' },
				{ path: '/academics/updatestaffphoto', title: 'Update Staff Photo', type: 'link' },
				{ path: '/academics/updatestudentsection', title: 'Update Student Section', type: 'link' },
				{ path: '/academics/pgcoursemapping', title: 'PG Course Mapping', type: 'link' },
				{ path: '/academics/assistantcontrollerapproves', title: 'AC Approve', type: 'link' },
				{ path: '/academics/assistantregistrarapproves', title: 'AR Approve', type: 'link' },
				{ path: '/academics/libraryapproval', title: 'Librarian Approval', type: 'link' },
				{ path: '/academics/hostelapproval', title: 'Hostel Approval', type: 'link' },
			]
		},
		{
			title: 'Midterm', icon: 'box', type: 'sub', badgeType: 'primary', active: false, children: [
				{ path: '/midterm/componentmasster', title: 'Component Master', type: 'link' },
				{ path: '/midterm/componentcoursetype', title: 'Component Course Type', type: 'link' },
				{ path: '/midterm/internalmarksconfigpgs', title: 'Internal Marks Config PG', type: 'link' },
				{ path: '/midterm/manualinternalmarkspg', title: 'Manual Marks Update PG', type: 'link' },
				{ path: '/midterm/internalmarksreportspg', title: 'Component Report PG', type: 'link' },
				{ path: '/midterm/componentmarksreportpg', title: 'Internal Marks Report PG', type: 'link' }
			]
		},
		{
			title: 'Pre-Exam', icon: 'users', type: 'sub', badgeType: 'primary', active: false, children: [
				{ path: '/preexam/semesterregistrationpg', title: 'Semester Registration PG', type: 'link' },
			]
		},
		{
			title: 'Post Exam', icon: 'book', type: 'sub', badgeType: 'primary', active: false, children: [
				{ path: '/postexam/gradepointupdatepg', title: 'Grade Point Update', type: 'link' },
				{ path: '/postexam/gradeupdatepg', title: 'Student Wise Grade', type: 'link' },
				{ path: '/postexam/downloadgradesheet', title: 'Updated Grade Sheet', type: 'link' },
				{ path: '/postexam/emptygradesheet', title: 'Empty Grade Sheet', type: 'link' },
				{ path: '/postexam/generatecomponentgrade', title: 'Generate Grade', type: 'link' },
				{ path: '/postexam/generateprogresscard', title: 'Generate Progress Card', type: 'link' },
				{ path: '/postexam/downloadstudentcard', title: 'Download Student Card', type: 'link' },
				{ path: '/postexam/downloadogpa', title: 'Download OGPA', type: 'link' },
				{ path: '/postexam/downloadpdc', title: 'Download PDC', type: 'link' },
				{ path: '/postexam/mastersheets', title: 'Master Sheet', type: 'link' },
			//	{ path: '/postexam/approvegrades', title: 'Approve Grade HOD', type: 'link' },
			{ path: '/postexam/charactercertificatepg', title: 'Character Certificate PG', type: 'link' },
			{ path: '/postexam/migrationcertificatepg', title: 'Migration Certificate PG', type: 'link' },
			]
		},
		
		{
			title: 'PG Forms', icon: 'columns', type: 'sub', badgeType: 'primary', active: false, children: [
				{ path: '/pgforms/form1', title: 'Form 1', type: 'link' },
				{ path: '/pgforms/form2', title: 'Form 2', type: 'link' },
				{ path: '/pgforms/form3', title: 'Form 3', type: 'link' },
				{ path: '/pgforms/form4', title: 'Form 4', type: 'link' },
				{ path: '/pgforms/form5', title: 'Form 5', type: 'link' },
				{ path: '/pgforms/formsaproval', title: 'Forms Approval', type: 'link' },
				{ path: '/pgforms/semesterregapproval', title: 'Semester Reg. Approval', type: 'link' },
				{ path: '/pgforms/examapproval', title: 'Exam Approval', type: 'link' },
				{ path: '/pgforms/examinerapproval', title: 'Examiner Approval', type: 'link' },
				{ path: '/pgforms/documentapproval', title: 'Document Approval', type: 'link' },
				{ path: '/pgforms/finalapproval', title: 'Final Approval', type: 'link' },

			]
		},
		{
			title: 'Feedback', icon: 'thumbs-up', type: 'sub', badgeType: 'primary', active: false, children: [
				{ path: '/feedback/feedbackscale', title: 'Feedback Scale', type: 'link' },
				{ path: '/feedback/aboutfeedback', title: 'About Feedback', type: 'link', },
			]
		},

		{
			title: 'Convocation', icon: 'award', type: 'sub', badgeType: 'primary', active: false, children: [
				{ path: '/convocation/donormaster', title: 'Donor Master', type: 'link' },
				{ path: '/convocation/uploadutility', title: 'Upload Utility', type: 'link', },
				{ path: '/convocation/donorcertificate', title: 'Donor Certificates', type: 'link' },
				{ path: '/convocation/convocationcertificate', title: 'Convocation Certificate', type: 'link', },
				{ path: '/convocation/goldmedalcertificate', title: 'Gold Medal Certificate', type: 'link', },
				{ path: '/convocation/convocationreport', title: 'Convocation Report', type: 'link' },
			]
		},
		{
			title: 'Hostel Management', icon: 'home', type: 'sub', badgeType: 'primary', active: false, children: [
				{ path: '/hostelmanagement/hostelmaster', title: 'Hostel Master', type: 'link' },
				{ path: '/hostelmanagement/hostelfeetype', title: 'Hostel Fee Type', type: 'link', },
				{ path: '/hostelmanagement/hostelfeeconfig', title: 'Hostel Fee Config', type: 'link' },
				{ path: '/hostelmanagement/hostelallocation', title: 'Hostel Allocation', type: 'link', },
				{ path: '/hostelmanagement/hosteldeallocation', title: 'Hostel De-Allocation', type: 'link', },
			]
		},
		{
			title: 'Fee Management', icon: 'credit-card', type: 'sub', badgeType: 'primary', active: false, children: [
				{ path: '/feemanagement/createfeename', title: 'Create Fee Name', type: 'link', },
				{ path: '/feemanagement/feemaster', title: 'Fee Master', type: 'link' },
				{ path: '/feemanagement/penaltyconfig', title: 'Penalty Configuration', type: 'link', },
				{ path: '/feemanagement/offlinepayment', title: 'Offline Payment', type: 'link', },
				{ path: '/feemanagement/nriofflinepayment', title: 'NRI Offline Payment', type: 'link', },
				{ path: '/feemanagement/miscellaneousfee', title: 'Miscellaneous Fee', type: 'link', },
				{ path: '/feemanagement/verifyfeess', title: 'Verify Fees', type: 'link', },
			
			]
		},
		{
			title: 'Utility', icon: 'message-circle', type: 'sub', badgeType: 'primary', active: false, children: [
				{ path: '/utility/courseregistrationpg', title: 'Course Registration', type: 'link', },
				{ path: '/utility/addordropcoursespg', title: 'Add/Drop Courses', type: 'link', },
				{ path: '/utility/gradestatuspg', title: 'Grade Status', type: 'link', },
			]
		},
		{
			title: 'Reports', icon: 'download', type: 'sub', badgeType: 'primary', active: false, children: [
				{ path: '/reports/studentreport', title: 'Student', type: 'link' },
				{ path: '/reports/systemreport', title: 'System', type: 'link', },
				{ path: '/reports/examinationreport', title: 'Examination', type: 'link' },
				{ path: '/reports/resultreport', title: 'Result', type: 'link' },
				{ path: '/reports/accountreport', title: 'Account', type: 'link' },
				{ path: '/reports/historyreports', title: 'History Report', type: 'link' },
			]
		},

	]
	FACULTYMENUITEMSUG: Menu[] = [
		{
			title: 'Faculty', icon: 'briefcase', type: 'sub', badgeType: 'primary', active: false, children: [
				{ path: '/faculty/internalmarksupload', title: 'Internal Marks Upload', type: 'link' },
				{ path: '/faculty/internalmarksexcel', title: 'Excel Marks Upload', type: 'link' },
				{ path: '/faculty/manualmarksupdates', title: 'Manual Marks Update', type: 'link' },
				{ path: '/faculty/facultycomponentmarksreports', title: 'Component Marks Report', type: 'link' },
			]
		},
	]
	FACULTYMENUITEMSPG: Menu[] = [
		{
			title: 'Faculty', icon: 'briefcase', type: 'sub', badgeType: 'primary', active: false, children: [
				{ path: '/faculty/updategradepoint', title: 'Update Grade Point', type: 'link' },
				{ path: '/faculty/excelmarksuploadpg', title: 'Excel Marks Upload PG', type: 'link' },
				{ path: '/faculty/manualmarksupdatepg', title: 'Manual Marks Update PG', type: 'link' },
				{ path: '/faculty/facultycomponentmarksreports', title: 'Component Marks Report', type: 'link' },
			]
		},
	]
	STUDENTMENUITEMSUG: Menu[] = [
		{
			title: 'Student UG', icon: 'user', type: 'sub', badgeType: 'primary', active: false, children: [
				{ path: '/student/coursedetails', title: 'Course Details', type: 'link' },
				{ path: '/student/semesterregistration', title: 'Semester Registration', type: 'link' },
				{ path: '/student/miscellaneousfee', title: 'Miscellaneous Fee', type: 'link' },
				{ path: '/student/supplementary', title: 'Supplementary', type: 'link' },
				{ path: '/student/reevaluation', title: 'Re-Evaluation', type: 'link' },
				{ path: '/student/photocopy', title: 'Photocopy', type: 'link' },
				{ path: '/student/supplementaryreeval', title: 'Supplementary Re-Eval', type: 'link' },
				{ path: '/student/supplephotocopy', title: 'Supple Photocopy', type: 'link' },
				{ path: '/student/reregistration', title: 'Re-Registration', type: 'link' },
				{ path: '/student/hostelpayment', title: 'Hostel Payment', type: 'link' },
				{ path: '/student/feedback', title: 'Feedback', type: 'link' },
				{ path: '/student/nrifee', title: 'NRI Fee', type: 'link' },
				{ path: '/student/convocation', title: 'Convocation', type: 'link' },

			]

		},
		{
			title: 'Helpdesk', icon: 'help-circle', type: 'sub', badgeType: 'primary', active: false, children: [
				{ path: '/helpdesk/helpdesk', title: 'Helpdesk', type: 'link' },
				{ path: '/helpdesk/tickethistory', title: 'Ticket History', type: 'link' },
			]
		},
		{
			title: 'Reports', icon: 'trending-up', type: 'sub', badgeType: 'primary', active: false, children: [
				{ path: '/reports/feereceipt', title: 'Fee Receipt', type: 'link' },
				{ path: '/reports/coursereceipt', title: 'Course Receipt', type: 'link' },
			]
		},

	]
	STUDENTMENUITEMSPG: Menu[] = [
		{
			title: 'Student PG', icon: 'user', type: 'sub', badgeType: 'primary', active: false, children: [
				{ path: '/student/coursedetails', title: 'Course Details', type: 'link' },
				{ path: '/student/updateprofile', title: 'Update Profile', type: 'link' },
				{ path: '/student/semesterregistrationpg', title: 'Semester Registration', type: 'link' },
				{ path: '/student/reevaluationpg', title: 'Re-Evaluation', type: 'link' },
				{ path: '/student/photocopypg', title: 'Photocopy', type: 'link' },
				{ path: '/student/changeordropcourse', title: 'Change / Drop Of Course', type: 'link' },
				{ path: '/student/miscellaneousfee', title: 'Miscellaneous Fee', type: 'link' },
				{ path: '/student/gradepoint', title: 'Grade Points', type: 'link' },
				{ path: '/student/hostelpayment', title: 'Hostel Payment', type: 'link' },
				{ path: '/student/feedback', title: 'Feedback', type: 'link' },
				{ path: '/student/convocation', title: 'Convocation', type: 'link' },

			]

		},
		{
			title: 'Helpdesk', icon: 'help-circle', type: 'sub', badgeType: 'primary', active: false, children: [
				{ path: '/helpdesk/helpdesk', title: 'Helpdesk', type: 'link' },
				{ path: '/helpdesk/tickethistory', title: 'Ticket History', type: 'link' },
			]
		},
		{
			title: 'Reports', icon: 'trending-up', type: 'sub', badgeType: 'primary', active: false, children: [
				{ path: '/reports/feereceipt', title: 'Fee Receipt', type: 'link' },
				{ path: '/reports/coursereceipt', title: 'Course Receipt', type: 'link' },
			]
		},

	]

	finArray1 = new Array()

	initializeItems(userDetails: any) {
		const parsedUserDetails = JSON.parse(userDetails);

		if (parsedUserDetails?.[0]?.loginType === 'staff') {
			if (parsedUserDetails?.[0]?.degree === 'UG') {
				this.itemsSubject.next(this.FACULTYMENUITEMSUG);
			} else {
				this.itemsSubject.next(this.FACULTYMENUITEMSPG);
			}
		}

		if (parsedUserDetails?.[0]?.loginType === "student") {
			if (parsedUserDetails?.[0]?.degree === "UG") {
            let showConvocationAndProfile = true; // Initialize to true

					if (parsedUserDetails?.[0]?.degree === "UG") {
						showConvocationAndProfile =
							(parsedUserDetails[0].programmeyearid === 4 && parsedUserDetails[0].semesterid === 2 )
					}
				

				const updatedStudentMenu = this.STUDENTMENUITEMSUG.map(menuGroup => {
				
					const updatedGroup = { ...menuGroup };

					
					if (updatedGroup.children) {
						
						updatedGroup.children = updatedGroup.children.filter(item => {
						
							if (item.title === 'NRI Fee' && !parsedUserDetails[0].isNRI) {
								return false; 
							}

							
						if (item.title === 'Convocation' && !showConvocationAndProfile) {
							return false; 
						  }
					
						 
						  return true;
						});
					  }
					
					  return updatedGroup;
					});

				this.itemsSubject.next(updatedStudentMenu);
			} else {
				let showConvocationAndProfile = true; 

				if (parsedUserDetails?.[0]?.loginType === "student") {
					if (parsedUserDetails?.[0]?.degree === "PG") {
						showConvocationAndProfile =
							(parsedUserDetails[0].programmeyearid === 2 && parsedUserDetails[0].semesterid === 2 && parsedUserDetails[0].programetype === 2) ||
							(parsedUserDetails[0].programmeyearid === 3 && parsedUserDetails[0].semesterid === 2 && parsedUserDetails[0].programetype === 1) || (parsedUserDetails[0].programmeyearid >= 2)
					}
				}
				const updatedStudentMenu = this.STUDENTMENUITEMSPG.map(menuGroup => {
				
					const updatedGroup = { ...menuGroup };
				  
					
					if (updatedGroup.children) {
					
					  updatedGroup.children = updatedGroup.children.filter(item => {
						
						if (item.title === 'Update Profile' && parsedUserDetails[0].isStudentUpdate === 1) {
						  return false; 
						}
				  
						
						if (item.title === 'Convocation' && !showConvocationAndProfile) {
						  return false; 
						}
				  
						
						return true;
					  });
					}
				  
					return updatedGroup;
				  });
				  
				  this.itemsSubject.next(updatedStudentMenu);
				}
		}
		if (parsedUserDetails?.[0]?.loginType != "student" && parsedUserDetails?.[0]?.loginType != "staff") {
			if (
				parsedUserDetails?.[0]?.roleName === 'Admin' &&
				parsedUserDetails?.[0]?.loginType === 'regular'
			) {
				if (parsedUserDetails?.[0]?.degree === 'UG') {
					this.itemsSubject.next(this.MENUITEMS);
				} else {
					this.itemsSubject.next(this.MENUITEMSPG);
				}
			} else {
				 this.finArray1 = [];
				this.finData = [];
				this.rolesdetails = localStorage.getItem('rolesdetails');
				this.finArray1.push(JSON.parse(this.rolesdetails));
			
				for (var i = 0; i < this.finArray1[0].length; i++) {
					if (
						this.finArray1[0][i]?.title !== 'Student' &&
						this.finArray1[0][i]?.title !== 'System' &&
						this.finArray1[0][i]?.title !== 'Examination' &&
						this.finArray1[0][i]?.title !== 'Result' &&
						this.finArray1[0][i]?.title !== 'Account' &&
						this.finArray1[0][i]?.title !== 'History Report'
					) {
						this.menuItemArray[i] = this.finArray1[0][i]?.subItems?.filter((menu) => {
							return menu.approverole === true;
						});
					}
				}

				for (var i = 0; i < this.finArray1[0].length; i++) {
					let newObj = {
						title: '',
						icon: '',
						type: '',
						badgeType: '',
						active: '',
						path: '',
						children: [],
					};
					newObj.title = this.finArray1[0][i]?.title;
					newObj.icon = this.finArray1[0][i]?.icon;
					newObj.type = this.finArray1[0][i]?.type;
					newObj.badgeType = this.finArray1[0][i]?.badgeType;
					newObj.active = this.finArray1[0][i]?.active;
					newObj.path = this.finArray1[0][i]?.path;
					newObj.children = this.menuItemArray[i];

					if (newObj.children) {
						this.finData.push(newObj);
					}
				}

				this.finalsArray = this.finData.filter((obj) => {
					if(obj.title==='Dashboard'){
						return true;
					  }else if(obj.hasOwnProperty('children') && obj['children'] && obj['children'].length !== 0){
						return true;
					  }else if(!obj.hasOwnProperty('children')){
						return true
					  }else if(obj['children']=="undefined"){
						return false
					  } else{
						return false
					  }

					// if (obj.title === 'Dashboard') {
					// 	return true;
					// } else if (
					// 	obj.hasOwnProperty('children') &&
					// 	obj['children'] &&
					// 	obj['children'].length !== 0
					// ) {
					// 	return true;
					// } else if (!obj.hasOwnProperty('children')) {
					// 	return true;
					// } else {
					// 	return false;
					// }
				});

			
				this.itemsSubject.next(this.finalsArray);

				
			}
		}
	}
}
